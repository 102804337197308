const campaign = document.getElementById('banner');
const reducedTitle = document.getElementById('reduced-title');
const reduceBtn = document.getElementById('reduce-btn');
const campaignOpen = document.getElementsByClassName('campaign-open');
const signinBtn = document.getElementById('signin-btn');
const reducedBanner = document.getElementsByClassName(
  'done-campaign-fix-reduced'
);
const callbackBtn = document.getElementById('callbackCTA');

function updateBannerPosition() {
  const callbackRect = callbackBtn.getBoundingClientRect();

  for (const element of reducedBanner) {
    element.style.left = callbackRect.left + callbackRect.width - 650 + 'px';
    element.style.right = 'auto';
  }
}

window.addEventListener('resize', updateBannerPosition);

document.addEventListener('scroll', () => {
  for (const item of darkSections) {
    const itemRect = item.getBoundingClientRect();

    if (areIntersecting(itemRect, buttonRect)) {
      campaign.classList.add('done-campaign-fix-grey');
      reduceBtn.classList.add('campaign-reduce-grey');
      signinBtn.classList.add('btn-secondary');
      signinBtn.classList.remove('btn-primary');

      for (const element of reducedBanner) {
        element.classList.add('done-campaign-fix-black');
      }

      return;
    }
    campaign.classList.remove('done-campaign-fix-grey');
    reduceBtn.classList.remove('campaign-reduce-grey');
    signinBtn.classList.remove('btn-secondary');
    signinBtn.classList.add('btn-primary');

    for (const element of reducedBanner) {
      element.classList.remove('done-campaign-fix-black');
    }
  }
});

function campaignClick() {
  localStorage.setItem('webinarCampaign', 'true');
  document.getElementById('campaign').style.display = 'none';
}

function reduceClick() {
  for (const element of campaignOpen) {
    element.style.display = 'none';
  }
  reducedTitle.classList.remove('reduced-title-hidden');
  campaign.classList.add('done-campaign-fix-reduced');

  updateBannerPosition();

  for (const item of darkSections) {
    const itemRect = item.getBoundingClientRect();

    if (areIntersecting(itemRect, buttonRect)) {
      campaign.classList.add('done-campaign-fix-black');

      return;
    }
  }
}

function displayClick() {
  for (const element of campaignOpen) {
    element.removeAttribute('style');
  }

  for (const element of reducedBanner) {
    element.removeAttribute('style');
  }
  reducedTitle.classList.add('reduced-title-hidden');
  campaign.classList.remove('done-campaign-fix-reduced');
  campaign.classList.remove('done-campaign-fix-black');
  updateBannerPosition();
}
